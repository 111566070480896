/* -----------------------------------------------------------------------------
	16px    = 1rem
    1px     = 0.0625rem

	300/400 - normal
	600     - bold
----------------------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&subset=cyrillic,latin-ext');

html {
	font-size: 100%;            /* 1rem = 16px (Default text size) */
}

body {
	font-family:    'Open Sans', sans-serif;
    color:          #333333;    /* black 80% */
	font-size:      1rem;		/* 16px */
	line-height:    1.5rem;		/* 24px (x1) */
	font-weight:    300;
}

h1, h2, h3, h4 {
    font-weight: 600;
}

h1 {
    font-size: 2.25rem;         /* 36px */
	line-height: 3rem;          /* 48px (x2) */
}

h2 {
    font-size: 1.75rem;         /* 28px */
	line-height: 3rem;          /* 48px (x2) */
}

h3 {
    font-size: 1.25rem;         /* 20px */
	line-height: 1.5rem;        /* 24px (x1) */
}

h4, p {
	font-size: 1rem;            /* 16px */
	line-height: 1.5rem;        /* 24px (x1) */
}

/* @TODO: add table, lists, hr and other... */
h1, h2, h3, h4, p {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
}

h1, h2, p, ul, table, .margin-bottom {
    margin-bottom: 1.5rem;      /* 24px (x1) */
}

.no-margin-bottom {
    margin-bottom: 0;
}

@media (min-width: 768px) {

    h1 {
        font-size: 4.25rem;		/* 68px */
        line-height: 4.5rem;	/* 72px (x3) */
    }

    h2 {
        font-size: 2.625rem;	/* 42px */
        line-height: 3rem;      /* 48px (x2) */
    }

    h3 {
        font-size: 1.625rem;    /* 26px */
        line-height: 3rem;      /* 48px (x2) */
    }
}

$background-color: #fff;
$text-color: #333;

html, body {
  height: 100%;
  scroll-behavior: smooth;
}
* {
  /* outline: 1px solid red; */
}

body {
  /* space for fixed navbar */
  // padding-top: 4.5rem; 
  font-size: 1rem;
}

/* Mobile menu */

body.nav-collapsed .navbar, .navbar-collapse.in {
  background-color: rgba(0,0,0,0.65);
}

h1 {
  font-size: 4.25rem;
  line-height: 1.1;
}

.container-fluid {
  max-width: 1920px;
  width: 100%;
  /* padding: 0; */
  margin-right: auto;
  margin-left: auto;
}

#contacts .container-fluid, footer .container-fluid, .navbar .container-fluid {
    max-width: 1440px;
}

a {
  // color: #333333;
  transition: color .3s ease-in-out;
}

a:hover,
a:focus {
  /* black 40% */
  color: #999999;
  text-decoration: none;
}

a,
.navbar-toggle {
  -webkit-transition: all 0.3s ease;
     -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
       -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
}

.img-container {
  position: relative;
  height: 0;
  overflow: hidden;
  /* 50% black */
  background-color: #808080;
}

.img-container.ratio-3x2 {
  /* 2/3*100 */
  padding-bottom: 66.66666667%;
}

@media(min-width: 768px) {
  body {
    padding-top: 0; 
  }
}

/* -----------------------------------------------------------------------------
  Navbar
----------------------------------------------------------------------------- */

.navbar {
    // transition: .6s linear;
    min-height: 0;
    // height: 4.5rem;
    margin-bottom: 0;
    border: none;
    background-color: $background-color;
    &.collapsed {
      background-color: $background-color;
    }
  }
  
  .navbar.dimm {
    background-color: $background-color;
  }
  
  .navbar-header {
    height: 4.5rem;
    position: relative;
    z-index: 100;
    background-color: $background-color; 
  }
  
  .navbar-brand {
    height: auto;
    padding-top: 0.8125rem;
    padding-bottom: 0.8125rem;
    padding-left: 2px;
  }
  
  .navbar-brand img {
    display: block;
    max-width: 60px;
    height: auto;
  }
  
  .navbar-toggle {
    padding: 1.5rem 24px;
    margin: 0;
    width: 72px;
    font-size: 2.25rem;
    text-align: center;
  
    color: $text-color;
  
    border: none;
    border-radius: initial;
  
    -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
         -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
  .navbar-toggle.collapsed {
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  
  .navbar-nav > li > a {
    padding: 1.1rem 24px;
    line-height: 1.5rem;
    text-transform: uppercase;
    text-align: right;
    color: #FFFFFF;
  }
  
  .navbar .social-links a {
    color: $text-color;
    border: none;
    padding: 1.5rem 7px;
    margin-right: 0;
  }
  
  .navbar .social-links {
    margin: 0;
    margin-left: 15px;
  }
  
  
  @media (min-width: 768px) {
  
    .navbar-right {
        margin-right: 0;
    }
  
    .navbar-nav > li > a {
        padding: 1.5rem 7px;
    }
  }
  
  .nav > li > a:hover,
  .nav > li > a:focus,
  .language-selector a:hover,
  .language-selector a:focus {
    background-color: transparent;
    /* black 40% */
    color: $text-color;
    text-decoration: none;
  }
  
  .language-selector-wrapper {
    display: none;
    background-color: $background-color;
    padding: 0 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .current-language {
    color: $text-color;
  }
  
  .language-arrow {
    cursor: pointer;
    transition: .2s linear;
  }
  
  a.current-language:hover {
    color: $text-color;
  }
  
  .current-language-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .language-selector {
    position: relative;
  }
  
  .language-selector a {
    color: $text-color;
  }
  
  .language-selector.desktop:hover .language-selector-wrapper {
    position: absolute;
    display: flex;
    right: 0px;
    
  }
  
  .language-selector.desktop:hover .language-arrow {
    transform: rotate(180deg);
  }
  
  .current-language-container:hover .language-selector-wrapper {
    position: absolute;
    display: flex;
    background-color: $background-color;
  }
  
  .navbar-nav > li.active > a {
    font-weight: 600;
  }
  
  .navbar-fixed-top .navbar-collapse {
    max-height: none;
    background-color: $background-color;
    border: none;
    box-shadow: none;
    height: 100vh;
  }
  
  .navbar-nav {
  margin-top: 0;
    margin-bottom: 0;
  }
  
  .navbar-collapse {
    transition: .3s linear;
  }
  
  
  .language-selector {
    margin-right: -15px;
    text-align: right;
    /* @TODO: move to global nav property: */
    text-transform: uppercase;
  }
  .language-selector a {
    display: inline-block;
    padding: 1.5rem 12px;
    line-height: 1.5rem;
  }
  .language-selector a:last-child {
    padding-right: 24px;
  }
  .language-selector.desktop {
    display: none;
    margin-left: 15px;
  }
  
  .social-links.desktop {
    display: none;
  }
  
  .language-selector.mobile .language-arrow {
    display: none;
  }
  
  .language-selector.mobile {
    display: block;
    width: 100%;
    float: right;
  }
  
  .language-selector.mobile .language-selector-wrapper {
    display: inline-block;
    padding: 0;
    background-color: transparent;
  }
  
  .language-selector.mobile .current-language-container {
    display: inline-block;
  }
  
  .language-selector.mobile .current-language {
    font-weight: 600;
  }
  
  
  @media (min-width: 768px) {
  
    .language-selector {
        float: right;
        margin-right: -7px;
    }
    .language-selector a,
    .language-selector a:last-child {
        padding: 1.5rem 7px;
    }
    .language-selector.desktop {
        display: block;
    }
    .language-selector.mobile {
        display: none;
    }
  
    .social-links.mobile {
        display: none;
    }
  
    .social-links.desktop {
        display: block;
    }
  }

/* -----------------------------------------------------------------------------
  Landing page
----------------------------------------------------------------------------- */
#landing-slideshow {
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
}

.full-width-container {
  /* height: 100vh; */
  max-width: 100%;
}

.language-arrow {
  background-size: 50px 44px;
  background-image: url('/assets/svg/arrow_down.svg');
  height: 20px;
  width: 20px;
  background-size: contain;
}

/* Swiper */
.swiper-container {
  width: 100%;
  height: auto;
}

.swiper-container .image {
  display: none;
}

.swiper-slide:not(.swiper-slide-active){
  opacity: 0 !important;
}

.swiper-slide img {
  width: 100%;
  max-width: unset;
}

@media (min-width: 768px) {
  .swiper-container {
      height: 100vh;
  }

  .swiper-container .image {
      display: block;
  }

  .swiper-slide img {
      display: none;
  }
}

.swiper-slide .image {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.swiper-button-prev {
  transition: .5s linear;
  opacity: 0.7;
  background-size: 40px 40px;
  background-image: url('/assets/svg/arrow_left.svg');
  left: 30px;
  :hover { 
    opacity: 1;
  }
  &::after {
    content: none
  }
}

.swiper-button-next {
  transition: .5s linear;
  opacity: 0.7;
  background-size: 40px 40px;
  background-image: url('/assets/svg/arrow_right.svg');
  right: 30px;
  :hover { 
    opacity: 1;
  }
  &::after {
    content: none
  }
}

.carousel-fade,
.carousel-fade .carousel-inner,
.carousel-fade .item {
  height: 100%;
}

.carousel-fade .landing-slide {
  width: 100%;
  height: 100%;
  background-color: #808080;
  background-position: center;

  -webkit-background-size: cover;
     -moz-background-size: cover;
          background-size: cover;
       -o-background-size: cover;
}

.carousel-fade .carousel-inner .item {
  opacity: 0;

  -webkit-transition-property: opacity;
          transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner > .item.next,
.carousel-fade .carousel-inner > .item.prev,
.carousel-fade .carousel-inner > .item.active.left,
.carousel-fade .carousel-inner > .item.active.right {
  opacity: 0;

  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-inner > .item.active,
.carousel-fade .carousel-inner > .item.next.left,
.carousel-fade .carousel-inner > .item.prev.right {
  opacity: 1;

  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.carousel-inner > .item {
-webkit-transition: 1.5s ease-in-out left;
     -o-transition: 1.5s ease-in-out left;
        transition: 1.5s ease-in-out left;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
      -webkit-transition: -webkit-transform 1.5s ease-in-out;
           -o-transition:      -o-transform 1.5s ease-in-out;
              transition:         transform 1.5s ease-in-out;
  }
}


/* -----------------------------------------------------------------------------
    Project list page
----------------------------------------------------------------------------- */
.project-thumb {
  padding: 0;
  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.project-thumb > a {
  display: block;
  position: relative;
}

.project-thumb > a > img {
  width: 100%;
}

.project-thumb .title {
  padding: 1.5rem 15px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  color: #333333;
}

.project-thumb .title span {
  text-shadow: 0px 0px 15px rgba(255, 255, 255, 1);
}

.project-thumb > a > .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  opacity: 0;

  background-color: rgba(255, 255, 255, 0.7);

  -webkit-transition: all 0.5s ease;
     -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
       -o-transition: all 0.5s ease;
          transition: all 0.5s ease;
}

.project-thumb > a:hover > .overlay,
.project-thumb > a:focus > .overlay,
.project-thumb > a.hover-on-tap.hover > .overlay {
  opacity: 1;
}

.project-list {
  padding-top: 4.5rem;
}

/* -----------------------------------------------------------------------------
  Project page
----------------------------------------------------------------------------- */
.cover {
  /* position: relative; */
  width: 100%;
  /* max-width: 1366px; */
  // height: 100%;
  // min-height: 50vh;
  margin: 0 auto;
  // background-color: #999999;
  background-position: center center;
  background-repeat: no-repeat;

  -webkit-background-size: cover;
     -moz-background-size: cover;
       -o-background-size: cover;
          background-size: cover;
}

.mobile-image-container {
  position: relative;
  display: block;
}

.cover {
  display: none;
}

.cover.fullscreen-block {
  min-height: 35vh;
  // height: 35vh;
}

.cover .title, .mobile-image-container .title {
  padding: 1.5rem 6px;
  margin: 0;
  position: absolute;
  bottom: 90px;
  left: 0;
  right: 0;
  color: #FFFFFF;
  max-width: 1366px;
  margin: 0 auto;
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
           0px 8px 13px rgba(0,0,0,0.1),
           0px 18px 23px rgba(0,0,0,0.1);
}

.mobile-image-container .title {
  font-size: 3rem;
  bottom: 0;
}

@media(min-width: 768px) {
  .cover .title {
      bottom: 0;
  }

  .mobile-image-container {
      display: none;
  }

  .cover {
      display: block;
  }

  .cover.fullscreen-block {
      // min-height: 93vh;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translateZ(0);
      -webkit-transform: translate3d(0, 0, 0);
  }
}

/* Video block */

.video-container {
position: relative;
padding-bottom: 56.25%; /* 16:9 */
padding-top: 25px;
height: 0;
}
.video-wrapper {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

/* Parallax effect */
.block{
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  font-size: 16px;
}
.block h2{
  position: relative;
  display: block;
  text-align: center;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10vw;
  color: white;
  font-weight: 400;
}
.img-parallax {
  width: 100vmax;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,0);
  pointer-events: none
}
  .fullscreen-block.paraxify {
      background-attachment: scroll
  }

  /*Fix for mobile browsers*/

  @media only screen 
      and (min-width: 1025px) {
          .fullscreen-block.paraxify {
              background-attachment: fixed;
          }
  }

/* -----------------------------------------------------------------------------
  Page grid
----------------------------------------------------------------------------- */

.page-grid [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.page-grid img {
  width: 100%;
  // max-width: 500px;
  // width: 100% !important;
  // height: auto !important;
}

.page-grid .text {
  padding: 1.5rem 35px;
}
.page-grid .text p {
  margin-bottom: 0;
}

.page-grid {
  padding-top: 4.5rem;
  .container-fluid {
    padding: 0;
  }
}

.block-row {
  &:not(:first-child) {
    .col-sm-12 {
      .img-wrapper {
        padding: 1px 0;
      }
    }
  }
  .col-sm-6 {
    &:first-child {
      .img-wrapper {
        padding-top: 1px;
        padding-bottom: 1px;
      }
    }
    &:last-child {
      .img-wrapper {
        padding-top: 1px;
        padding-bottom: 1px;
      }
    }
  }
  &:last-child {
    .col-sm-6 {
      &:last-child {
        .img-wrapper {
          padding-bottom: 0;
        }
      }
    }
    .col-sm-12 {
      &:last-child {
        .img-wrapper {
          padding-bottom: 0;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .page-grid {
    padding-top: 4.5rem;
    &.no-padding {
      padding: 0;
    }
  }

  .block-row {
    .col-sm-6 {
      order: 1;
      width: 100%;
      img {
        // max-width:50%;
      }
      &:first-child {
        .img-wrapper {
          padding-top: 1px;
          padding-right: 1px;
          padding-bottom: 1px;
        }
      }
      &:last-child {
        .img-wrapper {
          padding-top: 1px;
          padding-left: 1px;
          padding-bottom: 1px;
        }
      }
    }
    &:last-child {
      .col-sm-6 {
        .img-wrapper {
          padding-bottom: 0;
        }
      }
      .col-sm-12 {
        .img-wrapper {
          padding-bottom: 0;
        }
      }
    }
  }  
}

.col-sm-12 .text {
  max-width: 1366px;
  margin: 0 auto;
}

/* Fix for parallax */

.col-sm-12 {
  position: static;
}



/* -----------------------------------------------------------------------------
  Contacts page
----------------------------------------------------------------------------- */
#map {
  height: 300px;
}
#google-map {
  height: 100%;
}

#contacts {
  padding: 5rem 0 2rem 0;
}

/* -----------------------------------------------------------------------------
  Dark mode
----------------------------------------------------------------------------- */

footer .social-links a {
  color: #E1E1E1;
}

#contacts {
  padding: 5rem 0 0 0;
  background-color: $background-color;
}

#contacts .block-row .text {
  padding: 1.5rem 2.18rem 2.5rem 0.9rem;
}

/* .navbar .social-links a:hover {
  color: #1E1E1E;
} */

/* @FIX: make <ul> and get rid of "float". */
.social-links a {
  display: block;
  float: right;
  /* 5px */
  padding: 0.3125rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  border: 0.0625rem solid #333333;
  border-radius: 50%;
}

.social-links a {
  border: none;
}

.social-links a .fa {
  width: 1.5rem;
  font-size: 1.4rem;
}

.social-links a:first-child {
  margin-right: 0
}

.social-links a {
  margin-right: 15px;
}

.social-links a:hover,
.social-links a:focus {
  /* black 40% */
  color: #999999;
  border-color: #999999;
  text-decoration: none;
}

.page-grid .text {
  color: $text-color;
  padding: 1.5rem 0.8rem 2.5rem 0.8rem;
  height: 100%;
  // background-color: $background-color;
}

.col-sm-6 .page-grid .text {
  min-height: 35vh;
}

.page-grid .text h2 {
  position: relative;
  /* margin-bottom: 1.8rem; */
  /* line-height: 4rem; */
}

.col-sm-6 {
  // background-color: #121212;
}

.block-row .col-sm-6.inverse-order {
  order: 0;
}

.col-sm-6 .video-container {
  height: 100%;
  padding-top: 0;
}

/* .page-grid .col-sm-6 {
  padding: 6px;
} */

/* .full-width-container .block-row .col-sm-6:first-child {
  padding: 6px 0 0 0;
}

.full-width-container .block-row .col-sm-6:last-child {
  padding: 0 0 6px 0;
} */

.img-wrapper {
  height: 100%;
}

// .cover {
//   min-height: 35vh;
// }

body {
  // background-color: #1E1E1E;
  color: #E1E1E1;
}

footer {
  background-color: #121212;
}

.seo-text {
  margin: 2.5rem 0;
}

.col-sm-12:first-of-type {
  margin-top: 0;
}

.navbar-nav li {
  cursor: pointer;
}

.navbar-nav > li > a {
  color: $text-color;
}

.navbar-nav > li.active > a {
  color: $text-color;
  font-weight: 600;
}

.navbar {
  .navbar-header {
    .navbar-brand {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0;
      padding-left: 15px;
    }
  }
}

.navbar-brand img {
  // width: 9.875rem;
}

.block-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navbar-nav a:after {
  content: none;
}

@media (min-width: 768px) {
  .block-row {
      flex-direction: row;
      // min-height: 35vh;
  }

  .block-row .col-sm-6 {
      order: unset;
  }
  
  .block-row .col-sm-6.inverse-order {
      order: unset;
  }

  .cover {
      // min-height: 50vh;
  }

  .navbar-nav a:hover { 
      text-shadow:0px 0px 1.5px #fff;
      color: rgba(255,255,255,1);
  }

  .navbar-brand img {
      // width: 11.875rem;
  }
}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen 
and (min-width: 1024px) 
and (max-height: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5) {
 
}

/* Portrait */
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1.5) {
  .col-sm-12 .mobile-image-container {
      display: block;
  }

  .col-sm-12 .cover {
      display: none;
  }
  .col-sm-6 .cover {
      min-height: 35vh;
  }
}

/* Landscape */
@media only screen 
and (min-device-width: 1366px) 
and (max-device-width: 1366px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 1.5) {
  .col-sm-6 .cover {
      min-height: 50vh;
  }
  .fullscreen-block.paraxify {
      background-attachment: scroll;
  }
}

/* ----------- iPad ----------- */
@media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px)  { 
      .col-sm-6 .text {
          min-height: 30vh;
      }
      .cover {
        min-height: 25vh;
      }
      .fullscreen-block.paraxify {
          background-attachment: scroll;
      }
}

@media only screen 
  and (max-device-width : 1024px) 
  and (max-device-height : 768px)  { 
      .cover {
        min-height: 43vh;
      }
}

/* -----------------------------------------------------------------------------
    Footer
----------------------------------------------------------------------------- */
.seo-text {
    margin-top: 1.5rem;
}

footer {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

footer .copyright {
    line-height: 3rem;
    float: left;
}

.social-links {
    /* 6px */
    margin-top: 0.375rem;
    float: right;
}